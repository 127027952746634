import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./css/Navbar.css";

function Navbar() {
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (location.hash === "#testimonios") {
      scrollToSection("testimonios");
    } else if (location.hash === "#faq") {
      scrollToSection("faq");
    }
  }, [location.hash]);

  const handleTestimoniosClick = () => {
    if (location.pathname !== "/") {
      window.location.href = "/#testimonios";
    } else {
      scrollToSection("testimonios");
    }
  };

  const handleFaqClick = () => {
    if (location.pathname === "/proyectos") {
      window.location.href = "/#faq";
    } else {
      scrollToSection("faq");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          Typing Timing
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/servicios">
                Servicios
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/nosotros">
                Nosotros
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contacto">
                Contacto
              </Link>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={handleTestimoniosClick}
                style={{ background: "transparent", border: "none" }}
              >
                Testimonios
              </button>
            </li>
            <li
              className="nav-item"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="nav-link"
                onClick={handleFaqClick}
                style={{ background: "transparent", border: "none" }}
              >
                FAQ
              </button>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/proyectos">
                Proyectos
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
