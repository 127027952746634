import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimePicker from "react-time-picker"; // Importa el componente TimePicker
import "./css/Contacto.css";

function Contacto() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    servicio: '',
    mensaje: '',
    horario: '10:00', // Valor inicial para el horario
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleHorarioChange = (horario) => {
    setFormData({
      ...formData,
      horario: horario,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombre, email, telefono, servicio, mensaje, horario } = formData;
    const message = `Nombre: ${nombre}%0AEmail: ${email}%0ATeléfono: ${telefono}%0AServicio: ${servicio}%0AMensaje: ${mensaje}%0AHorario: ${horario}`;
    const url = `https://api.whatsapp.com/send?phone=541136633061&text=${message}`;

    try {
      window.open(url, '_blank');
      toast.success('Mensaje enviado correctamente');
    } catch (error) {
      console.error(error);
      toast.error('Error al enviar mensaje, inténtalo nuevamente');
    }
  };

  return (
    <section id="contacto" className="contacto-section dark-mode">
      <ToastContainer />
      <div className="container">
        <h2 className="contacto-heading text-center">
          Solicita un Presupuesto
        </h2>
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <div className="card contacto-card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre"
                      required
                      value={formData.nombre}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Correo Electrónico"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      className="form-control"
                      id="telefono"
                      name="telefono"
                      placeholder="Teléfono"
                      required
                      value={formData.telefono}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="servicio"
                      name="servicio"
                      required
                      value={formData.servicio}
                      onChange={handleChange}
                    >
                      <option value="">Selecciona el Servicio</option>
                      <option value="Programación Web">Programación Web</option>
                      <option value="Desarrollo de Aplicaciones Android">
                        Desarrollo de Aplicaciones Android
                      </option>
                      <option value="Desarrollo de Software">
                        Desarrollo de Software
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="horario">Horario de Contacto</label>
                    <br />
                    <TimePicker
                      onChange={handleHorarioChange}
                      value={formData.horario}
                      disableClock={true}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="mensaje"
                      name="mensaje"
                      rows="5"
                      placeholder="Descripción del Proyecto"
                      required
                      value={formData.mensaje}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Solicitar Presupuesto
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacto;
