import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Servicios from "./components/Servicios";
import Nosotros from "./components/Nosotros";
import Proceso from "./components/Proceso";
import Contacto from "./components/Contacto";
import Testimonios from "./components/Testimonios";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import "./App.css";
import Proyectos from "./components/Proyectos";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <br />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacto" element={<Contactos />} />
          <Route path="/servicios" element={<ServiciosPage />} />
          <Route path="/nosotros" element={<NosotrosPage />} />
          <Route path="/proyectos" element={<Proyectos />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <>
      <Header />
      <Servicios />
      <Nosotros />
      <Proceso />
      <Contacto />
      <Testimonios />
      <FAQ />
    </>
  );
}

function Contactos() {
  return (
    <>
      <br />
      <br />
      <Contacto />
      <FAQ />
    </>
  );
}

function NosotrosPage() {
  return (
    <>
      <br />
      <br />
      <Nosotros />
      <FAQ />
    </>
  );
}

function ServiciosPage() {
  return (
    <>
      <br />
      <br />
      <Servicios />
      <FAQ />
    </>
  );
}

export default App;
