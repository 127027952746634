import React from "react";
import "./css/Proceso.css";

function Proceso() {
  return (
    <section id="proceso" className="proceso-section dark-mode">
      <div className="container">
        <h2 className="proceso-title text-center mb-4">Nuestro Proceso</h2>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <div className="card proceso-card">
              <div className="card-body">
                <h5 className="card-title">Análisis de Requerimientos</h5>
                <p className="card-text">
                  Nos reunimos contigo para comprender tus necesidades y
                  objetivos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card proceso-card">
              <div className="card-body">
                <h5 className="card-title">Desarrollo Iterativo</h5>
                <p className="card-text">
                  Desarrollamos prototipos y realizamos pruebas iterativas para
                  mejorar el producto final.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card proceso-card">
              <div className="card-body">
                <h5 className="card-title">Lanzamiento y Mantenimiento</h5>
                <p className="card-text">
                  Implementamos la solución y proporcionamos soporte continuo
                  para garantizar su funcionamiento óptimo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Proceso;
