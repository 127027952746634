import React from "react";
import "./css/Header.css";

function Header() {
  return (
    <header className="header-section">
      <div className="container text-center">
        <h1 className="header-title">Desarrollo Tecnológico a tu Alcance</h1>
        <p className="header-subtitle">
          Transformamos tus ideas en soluciones digitales innovadoras
        </p>
        <a
          href="#contacto"
          className="btn btn-primary btn-lg header-btn"
          style={{ background: "#c778dd", border: "1px solid #c778dd" }}
        >
          Contáctanos
        </a>
      </div>
    </header>
  );
}

export default Header;
