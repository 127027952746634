import React from "react";
import "./css/FAQ.css";

function FAQ() {
  return (
    <section id="faq" className="faq-section dark-mode">
      <div className="container">
        <h2 className="faq-title text-center">Preguntas Frecuentes</h2>
        <div className="accordion" id="faqAccordion">
          <div className="card">
            <div className="card-header" id="faqHeading1">
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#faqCollapse1"
                  aria-expanded="true"
                  aria-controls="faqCollapse1"
                  style={{ color: "#c778dd" }}
                >
                  ¿Cuáles son los métodos de pago aceptados?
                </button>
              </h5>
            </div>
            <div
              id="faqCollapse1"
              className="collapse show"
              aria-labelledby="faqHeading1"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Aceptamos pagos mediante tarjeta de crédito o débito,
                transferencias, ArgenPer, Cryptos y más.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="faqHeading2">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#faqCollapse2"
                  aria-expanded="false"
                  aria-controls="faqCollapse2"
                  style={{ color: "#c778dd" }}
                >
                  ¿Cuál es el tiempo de entrega de los proyectos?
                </button>
              </h5>
            </div>
            <div
              id="faqCollapse2"
              className="collapse"
              aria-labelledby="faqHeading2"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                El tiempo de entrega de los proyectos depende de su complejidad
                y alcance. Normalmente, oscila entre 1 y 4 semanas.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="faqHeading3">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#faqCollapse3"
                  aria-expanded="false"
                  aria-controls="faqCollapse3"
                  style={{ color: "#c778dd" }}
                >
                  ¿Ofrecen servicios de mantenimiento?
                </button>
              </h5>
            </div>
            <div
              id="faqCollapse3"
              className="collapse"
              aria-labelledby="faqHeading3"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Sí, proporcionamos servicios de mantenimiento para asegurar el
                buen funcionamiento continuo de tus soluciones digitales.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
