import React from "react";
import "./css/Nosotros.css";

function Nosotros() {
  return (
    <section id="nosotros" className="nosotros-section dark-mode">
      <div className="container">
        <h2 className="nosotros-title text-center mb-4">Nuestra Historia</h2>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <p className="nosotros-text">
              Somos una empresa dedicada a proporcionar soluciones tecnológicas
              innovadoras a empresas de todos los tamaños. Hemos trabajado con
              clientes de diversos sectores y hemos creado soluciones a medida
              que impulsan su crecimiento y éxito.
            </p>
          </div>
          <div className="col-lg-6 mb-4">
            <img
              src="https://c1.wallpaperflare.com/preview/843/461/88/coding-code-programming-screen-thumbnail.jpg"
              // src="https://scontent.faep9-3.fna.fbcdn.net/v/t39.30808-6/421660832_375298038555240_4289991445757117979_n.png?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFqp5E3ZTeDEgHteU-_nIcDtuh8cyHmR-a26HxzIeZH5i4Jlw70xZKDWndDMY-_SGt5ETCToWb56cWdB93yUIHz&_nc_ohc=0YwE8rCWx9sAX-fQOqg&_nc_ht=scontent.faep9-3.fna&oh=00_AfBqNmcXMgmyRtD0MyyZ33-bcd_kZZnL-IFnJbWQEqedYg&oe=65F31840"
              alt="Nosotros"
              className="img-fluid rounded nosotros-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Nosotros;
