import React from "react";
import "./css/Servicios.css";

function Servicios() {
  return (
    <section id="servicios" className="servicios-section dark-mode">
      <div className="container">
        <h2 className="servicios-title text-center mb-4">
          Servicios Destacados
        </h2>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <div className="card servicio-card">
              <div className="card-body">
                <h5 className="card-title">Programación Web</h5>
                <p className="card-text">
                  Nuestro equipo de expertos en programación web desarrolla
                  sitios web modernos, funcionales y receptivos que se adaptan
                  perfectamente a las necesidades de tu empresa. Utilizando las
                  últimas tecnologías y mejores prácticas, creamos una presencia
                  en línea impresionante que atrae a tus clientes y refleja la
                  esencia de tu marca.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card servicio-card">
              <div className="card-body">
                <h5 className="card-title">
                  Desarrollo de Aplicaciones Android
                </h5>
                <p className="card-text">
                  Desde la concepción hasta el lanzamiento, nuestro equipo de
                  desarrollo de aplicaciones Android trabaja en estrecha
                  colaboración contigo para crear aplicaciones móviles
                  personalizadas que cautivan a tus usuarios. Con un enfoque en
                  la usabilidad, el rendimiento y la innovación, convertimos tus
                  ideas en aplicaciones móviles exitosas que destacan en el
                  mercado.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card servicio-card">
              <div className="card-body">
                <h5 className="card-title">Desarrollo de Software</h5>
                <p className="card-text">
                  Nuestro equipo de desarrollo de software diseña y desarrolla
                  soluciones personalizadas que se adaptan a las necesidades
                  específicas de tu empresa. Desde sistemas de gestión
                  empresarial hasta aplicaciones especializadas, trabajamos
                  contigo para crear software robusto, escalable y seguro que
                  impulsa la eficiencia operativa y el crecimiento empresarial.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Servicios;
