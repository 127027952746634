import React from "react";
import "./css/Proyectos.css";
import imgNestorMartinez from "../img/pages-portfolio/nestor_martinez.jpg";
import imgTecnicaDental from "../img/pages-portfolio/tecnica_dental.jpg";
import imgKonecta from "../img/pages-portfolio/konecta.png";
import imgComensales from "../img/pages-portfolio/comensales.png";
import imgSiTickets from "../img/pages-portfolio/sitickets.png";
import imgJoga from "../img/pages-portfolio/joga.png";

function Proyectos() {
  return (
    <section id="proyectos" className="proyectos-section dark-mode py-5">
      <div className="container">
        <h2 className="proyectos-title text-center mb-4">
          Descubre Nuestros Proyectos
        </h2>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div>
              <h3 className="proyectos-categoria">Proyectos de Clientes</h3>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <a target="_blank" href="https://nestormartinez1.com">
                    <div className="card proyecto-card">
                      <img
                        src={imgNestorMartinez}
                        className="card-img-top smaller-img"
                        alt="Nestor Martinez"
                      />
                      <div className="card-body">
                        <h5 className="card-title">Nestor Martinez</h5>
                        <p className="card-text">
                          Desarrollo de un tienda informativa de productos de
                          repuestos electronicos auditivos.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a target="_blank" href="https://tecnicadental.com.ar">
                    <div className="card proyecto-card">
                      <img
                        src={imgTecnicaDental}
                        className="card-img-top smaller-img"
                        alt="Tecnica Dental"
                      />
                      <div className="card-body">
                        <h5 className="card-title">Técnica Dental</h5>
                        <p className="card-text">
                          Desarrollo de un sitio informativo para prótesis
                          dentales.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a target="_blank" href="http://konecta.typingtiming.com.ar">
                    <div className="card proyecto-card">
                      <img
                        src={imgKonecta}
                        className="card-img-top smaller-img"
                        alt="Konecta+"
                      />
                      <div className="card-body">
                        <h5 className="card-title">Konecta+</h5>
                        <p className="card-text">
                          Desarrollo de un sitio donde podrás encontrar tu grupo
                          ideal.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a
                    target="_blank"
                    href="http://comensales.typingtiming.com.ar"
                  >
                    <div className="card proyecto-card">
                      <img
                        src={imgComensales}
                        className="card-img-top smaller-img"
                        alt="Konecta+"
                      />
                      <div className="card-body">
                        <h5 className="card-title">Comensales</h5>
                        <p className="card-text">
                          Desarrollo de un sitio software de administración de
                          un comensal y/o cualquier rúbro.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a target="_blank" href="https://www.sitickets.com">
                    <div className="card proyecto-card">
                      <img
                        src={imgSiTickets}
                        className="card-img-top smaller-img"
                        alt="Konecta+"
                      />
                      <div className="card-body">
                        <h5 className="card-title">SiTickets</h5>
                        <p className="card-text">
                          Desarrollo de una tienda de tickets para conciertos,
                          teatros y/o eventos deportivos.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a target="_blank" href="https://dev.jogafantasy.com">
                    <div className="card proyecto-card">
                      <img
                        src={imgJoga}
                        className="card-img-top smaller-img"
                        alt="Konecta+"
                      />
                      <div className="card-body">
                        <h5 className="card-title">
                          Joga Fantasy (en desarrollo)
                        </h5>
                        <p className="card-text">
                          Desarrollo de un sitio de partidos de futbol de
                          fantasia de la liga española.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h3 className="proyectos-categoria">Proyectos de Cursos</h3>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <a
                    target="_blank"
                    href="https://emanuelallaria.github.io/veterinaria"
                  >
                    <div className="card proyecto-card">
                      <div className="card-body">
                        <h5 className="card-title">Veterinaria</h5>
                        <p className="card-text">Sitio de veterinarias.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a
                    target="_blank"
                    href="https://emanuelallaria.github.io/adopcion"
                  >
                    <div className="card proyecto-card">
                      <div className="card-body">
                        <h5 className="card-title">Fundación Patitas</h5>
                        <p className="card-text">Sitio de adopciónes.</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a
                    target="_blank"
                    href="https://emanuelallaria.github.io/instafront"
                  >
                    <div className="card proyecto-card">
                      <div className="card-body">
                        <h5 className="card-title">InstaFront</h5>
                        <p className="card-text">
                          Sitio de replica de instagram.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <a
                    target="_blank"
                    href="https://emanuelallaria.github.io/super-heroes"
                  >
                    <div className="card proyecto-card">
                      <div className="card-body">
                        <h5 className="card-title">SuperHeroes</h5>
                        <p className="card-text">
                          Sitio de informacion de superheroes.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Proyectos;
