import React from "react";
import "./css/Testimonios.css";

function Testimonios() {
  return (
    <section id="testimonios" className="testimonios-section dark-mode py-5">
      <div className="container">
        <h2 className="testimonios-title text-center mb-4">
          Testimonios de Clientes
        </h2>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <div className="card testimonio-card">
              <div className="card-body">
                <p className="card-text">"Muy buen servicio."</p>
              </div>
              <div className="card-footer testimonio-footer">
                <strong>Nestor Martinez</strong>
                <br />
                Nestormartinez1
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card testimonio-card">
              <div className="card-body">
                <p className="card-text">
                  "Excelente trabajo, realizo el pedido personalizado
                  correctamente, muy buena la atención. Muchas gracias."
                </p>
              </div>
              <div className="card-footer testimonio-footer">
                <strong>Julieta Osorio</strong>
                <br />
                Técnico dental
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card testimonio-card">
              <div className="card-body">
                <p className="card-text">
                  "Gracias 🙏 <br />
                  Me fue de mucha ayuda la realización de la página web, son
                  unos duros."
                </p>
              </div>
              <div className="card-footer testimonio-footer">
                <strong>Camilo Burbano</strong>
                <br />A Un click de todo
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonios;
