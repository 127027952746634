import React from "react";
import "./css/Footer.css";

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container text-center">
        <p className="footer-text">
          © {new Date().getFullYear()} Typing Timing. Todos los derechos
          reservados.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
